import React from "react";
import DynamicPricingSection from "./../../sections/PricingSection/DynamicPricingSection"
import { graphql, useStaticQuery } from "gatsby";
import { getProperty } from "../../utils/helperFn";

const LandingResidential = (props) => {
    const data = useStaticQuery(graphql`
        query getPricingDataResidential {
            strapiCampaigns(slug: {
                eq: "index"
            }) {
                has_below_header_text
                pricing_section {
                    section_title
                    section_subtitle
                    section_location
                    section_explanation
                    call_us_title
                    call_us_subtitle
                    call_us_phone_number
                }
            }
        }`
    );

    const campaignData = getProperty(data, 'strapiCampaigns');

    return (
        <DynamicPricingSection
            location="index"
            searchParams={props.locate && props.locate.search}
            campaignData={campaignData}
            checkoutLanding={true}
            lead={props.lead}
            sprn={props.sprn}
            fullName={props.fullName}
        />
    ) 
}

export default LandingResidential;